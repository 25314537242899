import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { ROUTER_UNIVERSAL_PRODUCTS } from 'app/AppRouter';
import LandingPage from 'pages/UniversalProductsPage/LandingPage';
import ProductsPage from 'pages/UniversalProductsPage/ProductsPage';
import { Container, midPadding } from 'UI';

const UniversalProductsPage = () => {
  const { level1, level2, level3 } = useParams<{ level1?: string; level2?: string; level3?: string }>();
  const isData = Boolean(level1 && level2 && level3);
  return (
    <Container padding={midPadding} size={'xxl'}>
      <Switch>
        {isData && (
          <Route path={`${ROUTER_UNIVERSAL_PRODUCTS}/:level1/:level2/:level3`} render={() => <ProductsPage />} />
        )}
        <Route path="*" render={() => <LandingPage level1={level1} />} />
      </Switch>
    </Container>
  );
};

export default UniversalProductsPage;
