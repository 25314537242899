import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { RootState } from 'app/AppStore';
import { getPicture, getPictures, setPicture, setPictureNoDataStatus } from 'domains/pictures/Pictures.store';
import { getIsTimeSynced } from 'domains/time/Time.store';
import { fetchUrlSignature, getSignature, getSignatureExpiration } from 'domains/urlSignatures';
import { isSignatureExpired } from 'domains/urlSignatures/UrlSignatures.api';
import { getUserProfileSearchStatus } from 'domains/user';
import { FOUND, isError, isLoading, isNotFound, SEARCH_STATUS } from 'utils';

export const useFetchUrlImage = (imageKey: string | undefined, catalogSource: CatalogSource) => {
  const dispatch = useDispatch();
  const signature = useFetchSignature(catalogSource);
  const image = useSelector((state: RootState) => getPicture(state, imageKey));
  const urlSign = signature ? `?${signature}` : '';

  useEffect(() => {
    if (imageKey && signature && !image) {
      fetch(`${imageKey}${urlSign}`)
        .then((response) => response.text())
        .then((svg) => {
          dispatch(setPicture({ key: imageKey, imageBase64: btoa(svg) }));
        })
        .catch(() => {
          dispatch(setPictureNoDataStatus({ key: imageKey, status: 'error' }));
        });
    }
  }, [dispatch, image, imageKey, signature, urlSign]);
};

export const useFetchUrlImages = (imageKeys: string[], catalogSource: CatalogSource) => {
  const dispatch = useDispatch();
  const signature = useFetchSignature(catalogSource);
  const images = useSelector((state: RootState) => getPictures(state, imageKeys));
  const urlSign = signature ? `?${signature}` : '';

  useEffect(() => {
    images.forEach((image, imageKey) => {
      if (imageKeys && signature && !image) {
        fetch(`${imageKey}${urlSign}`)
          .then((response) => response.text())
          .then((svg) => {
            dispatch(setPicture({ key: imageKey, imageBase64: btoa(svg) }));
          })
          .catch(() => {
            dispatch(setPictureNoDataStatus({ key: imageKey, status: 'error' }));
          });
      }
    });
  }, [dispatch, images, imageKeys, signature, urlSign]);

  return images;
};

export function shouldFetchSignature(
  profileSearchStatus: SEARCH_STATUS,
  expiration: moment.Moment | undefined,
  signature: string | undefined,
) {
  if (profileSearchStatus !== FOUND) {
    return false;
  }

  return isSignatureExpired(expiration) && !isLoading(signature) && !isNotFound(signature) && !isError(signature);
}

export const useFetchSignature = (catalogSource: CatalogSource) => {
  const dispatch = useDispatch();
  const expiration = useSelector(getSignatureExpiration);
  const signature = useSelector(getSignature);
  const isTimeSyncedSearchData = useSelector(getIsTimeSynced);
  const profileSearchStatus = useSelector(getUserProfileSearchStatus);

  useEffect(() => {
    if (isTimeSyncedSearchData.data && shouldFetchSignature(profileSearchStatus, expiration, signature)) {
      dispatch(fetchUrlSignature());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, profileSearchStatus, JSON.stringify(expiration)]);

  return catalogSource === 'DATAHUB' ? signature : undefined;
};
