import { GET_SERVER_TIME_UTC_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { GET_SERVER_TIME } from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { RequestResponseMappingManager } from 'domains/webSockets/WebSocket.requestsResponseMapping';
import { WebSocketAction } from 'utils';
import { wsSendQueryAction } from 'utils/domainStore/api';

RequestResponseMappingManager.addMapping(GET_SERVER_TIME, GET_SERVER_TIME_UTC_RESPONSE);

export function sendGetServerUTCTime(): WebSocketAction {
  return wsSendQueryAction(GET_SERVER_TIME, null);
}
