import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Image as AntImage } from 'antd';
import styled from 'styled-components';
import { shouldFetchSignature, useFetchSignature } from 'domains/pictures/Pictures.requests';
import { getIsTimeSynced } from 'domains/time/Time.store';
import { fetchUrlSignature, getSignatureExpiration } from 'domains/urlSignatures';
import { getUserProfileSearchStatus } from 'domains/user';
import { CenteredSpin } from 'UI/Spin';
import { LOADING } from 'utils';

const SAntImage = styled(AntImage)`
  user-select: none;
`;

export const PUBLIC = 'public';
export const BASE64 = 'base64';
export const ASSETS = 'assets';
export const URL = 'url';
export type ImageSourceType = typeof PUBLIC | typeof BASE64 | typeof ASSETS | typeof URL;

export interface ImageProps {
  src?: string;
  fallback?: string;
  type?: ImageSourceType;
  alt: string;
  fallbackComponent?: React.ReactNode;
  width?: string | number;
  height?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  cursor?: string;
  catalogSource?: CatalogSource;
  className?: string;
  dataCy?: string;
}

export function imageSrcSwitch(type: ImageSourceType, src: string | undefined, signature?: string): string | undefined {
  if (!src) {
    return undefined;
  }
  switch (type) {
    case URL: {
      const url = src.replace('http://', 'https://');
      return signature ? `${url}?${signature}` : url;
    }
    case PUBLIC:
      return `/images/${src}`;
    case BASE64:
      return `data:image/jpeg;base64,${src}`;
    case ASSETS: {
      try {
        return require(`../../assets/${src}`);
      } catch (e) {
        console.error(e);
        return undefined;
      }
    }
  }
}

export const Image = ({
  src,
  fallback,
  fallbackComponent,
  type = PUBLIC,
  alt,
  width,
  height,
  maxWidth,
  maxHeight,
  cursor,
  catalogSource = 'IAM',
  className,
  dataCy,
}: ImageProps) => {
  const [useFallbackComponent, setUseFallbackComponent] = useState(false);
  const dispatch = useDispatch();
  const urlSignature = useFetchSignature(catalogSource);
  const isTimeSyncedSearchData = useSelector(getIsTimeSynced);
  const profileSearchStatus = useSelector(getUserProfileSearchStatus);
  const expiration = useSelector(getSignatureExpiration);
  const url = imageSrcSwitch(type, src, urlSignature);

  useEffect(() => {
    setUseFallbackComponent(!src);
  }, [src, urlSignature]);

  if (fallbackComponent && useFallbackComponent) {
    return <>{fallbackComponent}</>;
  }

  if (urlSignature === LOADING) {
    return <CenteredSpin />;
  }

  //Loading preview of external images should be handled by DataContainer
  return (
    <SAntImage
      src={url}
      fallback={imageSrcSwitch(type, fallback, urlSignature)}
      onError={() => {
        if (isTimeSyncedSearchData.data && shouldFetchSignature(profileSearchStatus, expiration, urlSignature)) {
          dispatch(fetchUrlSignature());
        } else {
          setUseFallbackComponent(true);
        }
      }}
      placeholder={type === URL ? <></> : <CenteredSpin />}
      width={width}
      height={height}
      preview={false}
      alt={alt}
      style={{ maxHeight, maxWidth, cursor }}
      className={className}
      data-cy={dataCy}
    />
  );
};
