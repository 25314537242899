import { GET_SERVER_TIME_UTC_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Init state
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { GET_SERVER_TIME_REQUEST, TIME_NAMESPACE, TimeState } from 'domains/time/Time.types';
import { SearchData } from 'utils';

const initialState: TimeState = {
  clientRequestTime: null,
  serverTime: null,
  timeDifferenceMs: null,
  isTimeSynced: {
    searchStatus: undefined,
    data: undefined,
  },
  showTimeIsNotSyncedPopup: true,
};

export const fetchServerTimeRequest = createAction(GET_SERVER_TIME_REQUEST);

export const fetchServerTimeResponse = createAction(GET_SERVER_TIME_UTC_RESPONSE);

// Slice
const slice = createSlice({
  name: TIME_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setClientRequestTime: (state, { payload }: PayloadAction<number>) => {
      state.clientRequestTime = payload;
    },
    setTimeDifferenceMs: (state, { payload }: PayloadAction<number>) => {
      state.timeDifferenceMs = payload;
    },
    setServerTime: (state, { payload }: PayloadAction<number>) => {
      state.serverTime = payload;
    },
    setIsTimeSynced: (state, { payload }: PayloadAction<SearchData<boolean>>) => {
      state.isTimeSynced = payload;
    },
    setShowTimeIsNotSyncedPopupFalse: (state) => {
      state.showTimeIsNotSyncedPopup = false;
    },
  },
});

// Actions
export const {
  setInitialState,
  setClientRequestTime,
  setServerTime,
  setTimeDifferenceMs,
  setIsTimeSynced,
  setShowTimeIsNotSyncedPopupFalse,
} = slice.actions;

// Getters/Selectors
export const getClientRequestTime = createSelector(
  (state: RootState) => state.time.clientRequestTime,
  (clientRequestTime) => clientRequestTime,
);
export const getServerTime = createSelector(
  (state: RootState) => state.time.serverTime,
  (serverTime) => serverTime,
);
export const getIsTimeSynced = createSelector(
  (state: RootState) => state.time.isTimeSynced,
  (isTimeSynced) => isTimeSynced,
);
export const getShowTimeIsNotSyncedPopup = createSelector(
  (state: RootState) => state.time.showTimeIsNotSyncedPopup,
  (showTimeIsNotSyncedPopup) => showTimeIsNotSyncedPopup,
);

// Export reducer
export default slice.reducer;
