import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { ExpiredTokenPopup, NoConnectionToBEPopup } from 'app/DisconnectPopup';
import { NotFound } from 'app/NotFound';
import { TimeNotSyncedPopup } from 'app/TimeNotSyncedPopup';
import BackToTopButton from 'components/BackToTopButton';
import PageViewCounter from 'components/Help/PageViewCounter';
import LoginRedirect from 'components/LoginRedirect';
import { Page } from 'components/Page';
import { SearchVehicleCountrySelection } from 'components/SearchVehicleCountrySelection/SearchVehicleCountrySelection';
import { SearchVehicleSelection } from 'components/SearchVehicleSelection/SearchVehicleSelection';
import SellerSettings from 'components/SellerSettings';
import SilentRenew from 'components/SilentRenew';
import UserProfileSelectionPopup from 'components/UserProfileSelectionPopup';
import PrivateRoute from 'composers/PrivateRoute';
import { useFetchAppConfig } from 'domains/appContext/AppContext.requests';
import { useFetchAuthCredentials } from 'domains/auth/Auth.requests';
import { useFetchBasket } from 'domains/basket/Basket.requests';
import { getExplodedIAMTree, getExplodedTree, getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { getDHCatalogSourcePaths, getIAMCatalogSourcePaths } from 'domains/catalog/Catalog.utils';
import { useFetchDeliveryLeadTime } from 'domains/deliveryLeadTime/DeliveryLeadTime.requests';
import { useFetchLastVehicleEstimate } from 'domains/estimate/Estimate.requests';
import { useSubscribeExternalBasket } from 'domains/externalBasket/ExternalBasket.requests';
import { useInitFirstHelpData } from 'domains/firstHelp/FirstHelp.requests';
import { getShowFirstHelp } from 'domains/firstHelp/FirstHelp.store';
import { useFetchCurrentGarageInfo } from 'domains/garage/Garage.requests';
import { useFetchInformationBanners } from 'domains/information/Information.requests';
import { useFetchMyStoreTires } from 'domains/myStore/MyStore.requests';
import {
  useFetchPromoNotificationNumber,
  useFetchPromotionBanners,
  useSubscribeCurrentPromotions,
} from 'domains/promotion/Promotion.requests';
import { useFetchIsTimeSynced } from 'domains/time/Time.requests';
import { getTokenUser, getUserProfileSearchStatus, UserRole } from 'domains/user';
import {
  useConnectToBE,
  useDisconnectWSOnUserInactivity,
  useFetchVehicleSearchHistorySubscription,
  useResetDelegateSessionOnLogout,
} from 'domains/user/User.requests';
import AssistancePage from 'pages/AssistancePage';
import BackOfficePage from 'pages/BackOfficePage';
import usePromotionNotification from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionNotification/usePromotionNotification';
import CartPage from 'pages/CartPage';
import CartConfirmationPage from 'pages/CartPage/CartConfirmationPage';
import CartDetailsPage from 'pages/CartPage/CartDetailsPage';
import ExternalBasketDialog from 'pages/CartPage/ExternalBasketDialog';
import CatalogPage from 'pages/CatalogPage/DH';
import FullTextSearchResult from 'pages/CatalogPage/IAM/FullTextSearchResult';
import IAMCatalogPage from 'pages/CatalogPage/IAM/IAMCatalogPage';
import { CookiesPage } from 'pages/CookiesPage';
import { EstimateRouter } from 'pages/EstimatePage/EstimateRouter';
import HomePage from 'pages/HomePage';
import LandingPage from 'pages/LandingPage';
import { IN_PROGRESS } from 'pages/MyOrdersPage/Orders.type';
import MyStorePage from 'pages/MyStorePage';
import OrderListPage from 'pages/OrderListPage';
import { PersonalDataPage } from 'pages/PersonalDataPage';
import ProductPage from 'pages/ProductPage';
import ProfilePage from 'pages/ProfilePage';
import { PromotionRouter } from 'pages/PromotionPage/PromotionRouter';
import SearchHistoryPage from 'pages/SearchHistoryPage';
import { TermsAndConditionsPage } from 'pages/TermsAndConditionsPage';
import TiresPage from 'pages/TiresPage';
import UniversalProductsPage from 'pages/UniversalProductsPage';
import { ERROR, hasData } from 'utils';
import { getBrowserLanguage } from 'utils/i18n/localeDetector';
import { appTracker } from './AppTracker';
import { WithPopup } from './WithPopup';

export const ROUTER_HOME = '/';
export const ROUTER_LOGIN_REDIRECT = '/idp-redirect';
export const ROUTER_SILENT_TOKEN_RENEW = '/silent-refresh.html';
export const ROUTER_PROFILE = '/profile';
export const CATALOG = '/catalog';
export const ROUTER_CATALOG_DH = `${CATALOG}/dh`;
export const ROUTER_CATALOG_DH_L1 = `${ROUTER_CATALOG_DH}/L1`;
export const ROUTER_CATALOG_DH_L3 = `${ROUTER_CATALOG_DH}/L3`;
export const ROUTER_CATALOG_IAM = `${CATALOG}/iam`;
export const ROUTER_CATALOG_VEHICLE = '/vehicle';
export const ROUTER_CATALOG_SEARCH = '/search';
export const ROUTER_CATALOG_DETAILS = '/details';
export const ROUTER_CATALOG_MAINTENANCE_PLAN = '/maintenance-plan';
export const ROUTER_CATALOG_TIRES = '/tires';
export const ROUTER_CART = '/cart';
export const ROUTER_CART_DETAILS = '/cart-order-details';
export const ROUTER_CART_CONFIRMATION = '/cart-confirmation';
export const ROUTER_HISTORY = '/history';
export const ROUTER_ORDERS = '/profile/orders';
export const ROUTER_IN_PROGRESS_ORDERS = `${ROUTER_ORDERS}/${IN_PROGRESS}`;
export const ROUTER_ACCOUNT = `${ROUTER_PROFILE}/account`;
export const ROUTER_MY_STORE = '/my-store';
export const ROUTER_MY_STORE_BUNDLES = `${ROUTER_MY_STORE}/bundles`;
export const ROUTER_MY_STORE_REFERENCES = `${ROUTER_MY_STORE}/references`;
export const ROUTER_MY_STORE_TIRES = `${ROUTER_MY_STORE}/tires`;
export const ROUTER_MY_STORE_SERVICES = `${ROUTER_MY_STORE}/services`;
export const ROUTER_MY_STORE_OTHERS = `${ROUTER_MY_STORE}/others`;
export const ROUTER_ESTIMATE = '/estimates';
export const ROUTER_NOT_FOUND = '/not-found';
export const ROUTER_SELLER = '/seller';
export const ROUTER_PRODUCT = '/product';
export const UNIVERSAL_PRODUCTS_LINK = 'universal-products';
export const ROUTER_UNIVERSAL_PRODUCTS = `/${UNIVERSAL_PRODUCTS_LINK}`;
export const ROUTER_ORDER_LIST = `/order-list`;
export const ROUTER_TIRES = '/tires';
export const ROUTER_DETAILS = '/technical-details';
export const ROUTER_TECHNICAL_DATA = '/technical-data';
export const ROUTER_REPAIR_METHOD = '/repair-method';
export const ROUTER_BACKOFFICE = '/backoffice';
export const ROUTER_PROMOTION = '/promotion';
export const ROUTER_ASSISTANCE = '/assistance';
export const ROUTER_ASSISTANCE_RENAULT_DACIA = `${ROUTER_ASSISTANCE}/renault-dacia`;
export const ROUTER_ASSISTANCE_OTHER_BRANDS = `${ROUTER_ASSISTANCE}/other-brands`;
export const ROUTER_CAR_PARTS = '/car-parts';
export const ROUTER_TECHNICAL_DOCUMENTATION = '/technical-documentation';
export const ROUTER_MEMBERSHIP_AND_SUPPORT = '/membership-and-support';
export const ROUTER_TERMS_AND_CONDITIONS_BASE = '/terms-and-conditions';
export const ROUTER_PERSONAL_DATA_BASE = '/personal-data';
export const ROUTER_COOKIES_BASE = '/cookies';
export const ROUTER_RETURN_ORDER = '/return';

export const matchRoute = (pathname: string, route: string, exact = false) =>
  exact ? pathname === route : pathname.includes(route);

const routerCountrySelector = (country: string | undefined) => {
  const selectedCountry = country?.toLowerCase() ?? getBrowserLanguage()?.getCountryCode().toLowerCase();
  return `/${selectedCountry}`;
};

export const ROUTER_TERMS_AND_CONDITIONS = (country: string | undefined) =>
  `${ROUTER_TERMS_AND_CONDITIONS_BASE}${routerCountrySelector(country)}`;
export const ROUTER_PERSONAL_DATA = (country: string | undefined) =>
  `${ROUTER_PERSONAL_DATA_BASE}${routerCountrySelector(country)}`;
export const ROUTER_COOKIES = (country: string | undefined) =>
  `${ROUTER_COOKIES_BASE}${routerCountrySelector(country)}`;

const AppRouter = () => {
  const history = useHistory();
  const [catalogShowMenu, setCatalogShowMenu] = useState<boolean>(true);
  const profilSearchStatus = useSelector(getUserProfileSearchStatus);
  const tokenUser = useSelector(getTokenUser);
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const iamExplodedTreeExists = hasData(useSelector((state: RootState) => getExplodedIAMTree(state, vehicleKey)));
  const datahubExplodedTreeExists = Boolean(useSelector((state: RootState) => getExplodedTree(state, vehicleKey)));
  const explodedTreeExists = datahubExplodedTreeExists || iamExplodedTreeExists;
  const isShowFirstHelp = Boolean(useSelector(getShowFirstHelp).showStreamId);

  appTracker.connectToHistory(history);
  appTracker.contentsquareConnectToHistory(history);

  useConnectToBE();
  useDisconnectWSOnUserInactivity();
  useFetchAppConfig();
  useFetchAuthCredentials();
  useFetchBasket();
  useFetchCurrentGarageInfo();
  useResetDelegateSessionOnLogout();
  useFetchDeliveryLeadTime();
  useFetchPromotionBanners();
  useFetchInformationBanners();
  useSubscribeCurrentPromotions();
  useInitFirstHelpData();
  usePromotionNotification();
  useFetchPromoNotificationNumber();
  useFetchVehicleSearchHistorySubscription();
  useSubscribeExternalBasket();
  useFetchLastVehicleEstimate();
  useFetchMyStoreTires();
  useFetchIsTimeSynced();

  const getAssistancePage = () => {
    if (!tokenUser || profilSearchStatus === ERROR) {
      return <AssistancePage />;
    }
    return (
      <Page openedSideBar={false} collapsedSideBar={!explodedTreeExists} displayBanner={false}>
        <AssistancePage />
      </Page>
    );
  };

  return (
    <WithPopup requiredRights={[UserRole.COMMAND, UserRole.CONNECT, UserRole.CONNECT_COMMANDE]}>
      <>
        <ExpiredTokenPopup />
        <NoConnectionToBEPopup />
        <TimeNotSyncedPopup />
        <UserProfileSelectionPopup />
        <SearchVehicleSelection />
        <SearchVehicleCountrySelection />
        <ExternalBasketDialog />
        <Switch>
          <PrivateRoute
            path={ROUTER_HOME}
            exact
            render={() => (
              <Page openedSideBar={isShowFirstHelp || explodedTreeExists} collapsedSideBar={!explodedTreeExists}>
                <PageViewCounter page={ROUTER_HOME} />
                <HomePage />
              </Page>
            )}
            fallback={() => <LandingPage />}
          />
          <Route path={ROUTER_LOGIN_REDIRECT} component={LoginRedirect} />
          <Route path={ROUTER_SILENT_TOKEN_RENEW} component={SilentRenew} />

          <Route path={ROUTER_TERMS_AND_CONDITIONS_BASE} component={TermsAndConditionsPage} />
          <Route path={ROUTER_PERSONAL_DATA_BASE} component={PersonalDataPage} />
          <Route path={ROUTER_COOKIES_BASE} component={CookiesPage} />
          <PrivateRoute
            path={ROUTER_PROFILE}
            render={() => (
              <Page openedSideBar={explodedTreeExists} collapsedSideBar={!explodedTreeExists}>
                <ProfilePage />
              </Page>
            )}
          />
          <PrivateRoute
            path={`${ROUTER_TIRES}/:level1?/:level2?/:level3?`}
            render={() => (
              <Page openedSideBar={false} collapsedSideBar={!explodedTreeExists}>
                <TiresPage />
              </Page>
            )}
          />
          <PrivateRoute
            path={`${ROUTER_UNIVERSAL_PRODUCTS}/:level1?/:level2?/:level3?`}
            render={() => (
              <Page openedSideBar={true} collapsedSideBar={false}>
                <UniversalProductsPage />
              </Page>
            )}
          />
          <PrivateRoute
            path={`${ROUTER_ORDER_LIST}`}
            render={() => (
              <Page openedSideBar={false} collapsedSideBar={!explodedTreeExists}>
                <OrderListPage />
              </Page>
            )}
          />
          <PrivateRoute
            path={`${ROUTER_PRODUCT}/:referenceNumber`}
            render={() => (
              <Page openedSideBar={explodedTreeExists} collapsedSideBar={!explodedTreeExists}>
                <ProductPage />
              </Page>
            )}
          />
          <PrivateRoute
            path={getIAMCatalogSourcePaths(`${ROUTER_CATALOG_VEHICLE}/search/:label`)}
            exact
            render={() => (
              <Page openedSideBar={true} collapsedSideBar={false}>
                <FullTextSearchResult />
              </Page>
            )}
          />
          <PrivateRoute
            path={getDHCatalogSourcePaths('/:query/:carPartGroupId?/:categoryId?/:subcategoryId?/:plateId?')}
            render={() => (
              <Page openedSideBar={catalogShowMenu} collapsedSideBar={false}>
                <CatalogPage setCatalogShowMenu={setCatalogShowMenu} />
              </Page>
            )}
          />
          <PrivateRoute
            path={getIAMCatalogSourcePaths('/:query/:carPartGroupId?/:categoryId?/:subcategoryId?/:plateId?')}
            render={() => (
              <Page openedSideBar={catalogShowMenu} collapsedSideBar={false}>
                <IAMCatalogPage setCatalogShowMenu={setCatalogShowMenu} />
              </Page>
            )}
          />
          <PrivateRoute
            path={ROUTER_CART}
            render={() => (
              <Page openedSideBar={false} collapsedSideBar={true}>
                <PageViewCounter page={ROUTER_CART} />
                <CartPage />
              </Page>
            )}
            requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
          />
          <PrivateRoute
            path={ROUTER_CART_DETAILS}
            render={() => (
              <Page openedSideBar={false} collapsedSideBar={true}>
                <CartDetailsPage />
              </Page>
            )}
            requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
          />
          <PrivateRoute
            path={ROUTER_CART_CONFIRMATION}
            render={() => (
              <Page openedSideBar={false} collapsedSideBar={true}>
                <CartConfirmationPage />
              </Page>
            )}
            requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
          />
          <PrivateRoute
            path={ROUTER_HISTORY}
            render={() => (
              <Page openedSideBar={explodedTreeExists} collapsedSideBar={!explodedTreeExists}>
                <SearchHistoryPage />
              </Page>
            )}
          />
          <PrivateRoute
            path={ROUTER_ESTIMATE}
            render={() => (
              <Page openedSideBar={explodedTreeExists} collapsedSideBar={!explodedTreeExists}>
                <EstimateRouter />
              </Page>
            )}
            requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
          />
          <PrivateRoute
            path={ROUTER_SELLER}
            render={() => (
              <Page openedSideBar={explodedTreeExists} collapsedSideBar={!explodedTreeExists}>
                <SellerSettings />
              </Page>
            )}
            requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
          />
          <PrivateRoute
            path={ROUTER_PROMOTION}
            render={() => (
              <Page openedSideBar={explodedTreeExists} collapsedSideBar={!explodedTreeExists}>
                <PromotionRouter />
              </Page>
            )}
          />
          <PrivateRoute
            path={ROUTER_BACKOFFICE}
            render={() => (
              <Page openedSideBar={false} collapsedSideBar={!explodedTreeExists} displayBanner={false}>
                <BackOfficePage />
              </Page>
            )}
            requiredRights={[UserRole.CENTRAL_ADMIN, UserRole.COUNTRY_ADMIN, UserRole.R1]}
          />
          <PrivateRoute
            path={ROUTER_MY_STORE}
            render={() => (
              <Page openedSideBar={false} collapsedSideBar={!explodedTreeExists} displayBanner={false}>
                <MyStorePage />
              </Page>
            )}
            requiredRights={[UserRole.R2, UserRole.R3, UserRole.R3_MOTRIO]}
          />
          <Route path={`${ROUTER_ASSISTANCE}`} render={() => getAssistancePage()} />
          <PrivateRoute
            path={ROUTER_NOT_FOUND}
            render={() => (
              <Page openedSideBar={explodedTreeExists} collapsedSideBar={!explodedTreeExists}>
                <NotFound />
              </Page>
            )}
          />
          <PrivateRoute render={() => <Redirect to={ROUTER_NOT_FOUND} />} />
        </Switch>
        <BackToTopButton />
      </>
    </WithPopup>
  );
};

export default AppRouter;
