/* eslint-disable max-len */
import { PROMOTION_SAVE_DISCOUNT_URL } from '@1po/1po-bff-fe-spec/generated/backoffice/BackofficeURLs';
import {
  ADD_ESTIMATE_KNOWN_LABOR_TIME_ERROR,
  AUTOCOMPLETE_BUNDLES_SEARCH_ERROR,
  BACKOFFICE_CROSS_SELLING_DELETE_FILE_ERROR,
  BACKOFFICE_CROSS_SELLING_GET_FILE_ERROR,
  BACKOFFICE_CROSS_SELLING_GET_REFERENCES_ERROR,
  BACKOFFICE_CROSS_SELLING_GET_REFERENCES_FILE_ERROR,
  BACKOFFICE_CROSS_SELLING_REPLACE_FILE_ERROR,
  BACKOFFICE_CROSS_SELLING_UPLOAD_FILE_ERROR,
  BACKOFFICE_CURRENT_PROMOTIONS_ERROR,
  BACKOFFICE_ELECTRONIC_PARTS_REPAIR_GET_FILE_ERROR,
  BACKOFFICE_ELECTRONIC_PARTS_REPAIR_REPLACE_REFERENCES_FILE_ERROR,
  BACKOFFICE_ELECTRONIC_PARTS_REPAIR_UPLOAD_REFERENCES_FILE_ERROR,
  BACKOFFICE_FULL_PROMOTION_ERROR,
  BACKOFFICE_INFORMATION_BANNERS_ERROR,
  BACKOFFICE_INFORMATION_ERROR,
  BACKOFFICE_INFORMATIONS_ERROR,
  BACKOFFICE_PROMOTION_BANNERS_ERROR,
  BACKOFFICE_PROMOTION_ERROR,
  BACKOFFICE_PROMOTION_UPLOAD_REFERENCES_FILE_ERROR,
  BACKOFFICE_PROMOTION_UPLOAD_TARGETED_AUDIENCE_FILE_ERROR,
  BACKOFFICE_PUBLISH_INFORMATION_ERROR,
  BACKOFFICE_SAVE_INFORMATION_ERROR,
  BACKOFFICE_SAVE_INFORMATION_TEMPLATE_ERROR,
  EXPLODED_TREE_ERROR,
  GET_AVAILABLE_DMS_SERVICES_ERROR,
  GET_DELEGATION_CONNECT_AS_RESPONSE_ERROR,
  GET_ESTIMATE_BY_ID_ERROR,
  GET_ESTIMATE_HISTORY_ERROR,
  GET_ESTIMATE_SETTINGS_ERROR,
  GET_EXPLODED_TREE_IAM_ERROR,
  GET_EXTERNAL_ORDER_LIST_ERROR,
  GET_FULL_TEXT_AUTOCOMPLETE_ERROR,
  GET_GARAGE_INFO_ERROR,
  GET_IAM_EQUIVALENT_REFERENCES_ERROR,
  GET_IAM_LABOR_TIMES_ERROR,
  GET_IAM_OPERATIONS_TREE_ERROR,
  GET_IAM_REFERENCES_ERROR,
  GET_IAM_REPAIR_METHOD_DETAIL_ERROR,
  GET_IAM_REPAIR_METHODS_ERROR,
  GET_IAM_REPAIR_METHODS_TREE_ERROR,
  GET_IAM_SERVICE_PROPOSAL_ERROR,
  GET_IAM_SERVICE_PROPOSAL_OPERATIONS_ERROR,
  GET_IAM_TECHNICAL_DATA_DETAILS_ERROR,
  GET_IAM_TECHNICAL_DATA_TREE_ERROR,
  GET_IAM_TEXT_SEARCH_ERROR,
  GET_IAM_TIRES_ERROR,
  GET_IAM_VEHICLE_BRANDS_ERROR,
  GET_IAM_VEHICLE_MODELS_ERROR,
  GET_IAM_VEHICLE_VERSIONS_ERROR,
  GET_LABOR_TIME_IDS_BY_GENERIC_PARTS_ERROR,
  GET_LABOR_TIMES_ERROR,
  GET_LATEST_ESTIMATE_ERROR,
  GET_LEVEL3_ERROR,
  GET_MAINTENANCE_PLAN_ERROR,
  GET_MAINTENANCE_VALUES_ERROR,
  GET_MARKETING_REFERENCES_ERROR,
  GET_MPR_VALIDATION_ERROR,
  GET_MY_STORE_BUNDLES_ERROR,
  GET_MY_STORE_TIRES_ERROR,
  GET_ORDER_BY_ID_ERROR,
  GET_ORDER_LIST_ERROR,
  GET_OTS_INFORMATION_ERROR,
  GET_PICTURE_ERROR,
  GET_PLATE_REFERENCES_ERROR,
  GET_PLATES_FOR_REFERENCE_ERROR,
  GET_POSSIBLE_BUYERS_TO_DELEGATE_RESPONSE_ERROR,
  GET_POSSIBLE_SELLERS_TO_DELEGATE_RESPONSE_ERROR,
  GET_REFERENCE_DETAILS_ERROR,
  GET_REFERENCE_TEXT_SEARCH_ERROR,
  GET_REFERENCES_PRICE_ERROR,
  GET_REFERENCES_REPAIR_PRICE_ERROR,
  GET_REFERENCES_STOCKS_ERROR,
  GET_REFERENCES_STOCKS_TIMEOUT_ERROR,
  GET_REPAIR_ORDER_ERROR,
  GET_REUSE_STOCK_ERROR,
  GET_TEXT_SEARCH_ERROR,
  GET_TIRE_BRAND_CATEGORIES_ERROR,
  GET_UNIVERSAL_PRODUCTS_ERROR,
  GET_UNIVERSAL_PRODUCTS_TREE_ERROR,
  GET_USER_BASKET_ERROR,
  GET_USER_SELLERS_ERROR,
  GET_VEHICLE_BRANDS_ERROR,
  GET_VEHICLE_ENGINES_ERROR,
  GET_VEHICLE_SEARCH_HISTORY_ERROR,
  GET_VEHICLE_TECHNICAL_CRITERIA_ERROR,
  SEARCH_VEHICLE_ERROR,
  SEND_CLAIM_ERROR,
  SEND_EMAIL_FROM_GARAGE_ERROR,
  SEND_EMAIL_TO_ASSISTANCE_ERROR,
  SEND_ESTIMATE_TO_DMS_ERROR,
  SEND_RETURN_REQUEST_EMAIL_ERROR,
  TIRES_SEARCH_PRODUCTS_ERROR,
  UPLOAD_BASKET_REFERENCES_FILE_ERROR_99_REFERENCES,
  UPLOAD_BASKET_REFERENCES_FILE_ERROR_API_CALL,
  UPLOAD_BASKET_REFERENCES_FILE_ERROR_TECHNICAL,
  UPLOAD_ORDER_LIST_REFERENCES_ERROR_API_CALL,
  UPLOAD_ORDER_LIST_REFERENCES_ERROR_TECHNICAL,
  UPLOAD_ORDER_LIST_REFERENCES_FILE_ERROR,
  UPLOAD_ORDER_LIST_REFERENCES_FILE_ERROR_99_REFERENCES,
  VEHICLE_TIRES_ERROR,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { GET_TIRE_BRAND_CATEGORIES } from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { createAction } from '@reduxjs/toolkit';
import { ADD_ORDER_LIST_REFERENCES_TO_CART_ERROR } from 'domains/orderList/OrderList.types';

// Saga actions
export const fetchUserConnectAsErrorSaga = createAction(GET_DELEGATION_CONNECT_AS_RESPONSE_ERROR);
export const getPossibleBuyersForSellerErrorSaga = createAction(GET_POSSIBLE_BUYERS_TO_DELEGATE_RESPONSE_ERROR);
export const getPossibleSellersForUserErrorSaga = createAction(GET_POSSIBLE_SELLERS_TO_DELEGATE_RESPONSE_ERROR);
export const fetchUserSellersErrorSaga = createAction(GET_USER_SELLERS_ERROR);
export const fetchSearchVehicleErrorSaga = createAction(SEARCH_VEHICLE_ERROR);
export const fetchSearchVehicleBrandsErrorSaga = createAction(GET_VEHICLE_BRANDS_ERROR);
export const fetchSearchVehicleEnginesErrorSaga = createAction(GET_VEHICLE_ENGINES_ERROR);
export const fetchIAMVehicleBrandsErrorSaga = createAction(GET_IAM_VEHICLE_BRANDS_ERROR);
export const fetchIAMVehicleModelsErrorSaga = createAction(GET_IAM_VEHICLE_MODELS_ERROR);
export const fetchIAMVehicleVersionsErrorSaga = createAction(GET_IAM_VEHICLE_VERSIONS_ERROR);
export const fetchPlateReferencesErrorSaga = createAction(GET_PLATE_REFERENCES_ERROR);
export const fetchMarketingReferencesErrorSaga = createAction(GET_MARKETING_REFERENCES_ERROR);
export const fetchReferencesStocksErrorSaga = createAction(GET_REFERENCES_STOCKS_ERROR);
export const fetchReferencesStocksTimeoutErrorSaga = createAction<{ references: string[] | undefined }>(
  GET_REFERENCES_STOCKS_TIMEOUT_ERROR,
);

export const fetchReferencesPriceErrorSaga = createAction(GET_REFERENCES_PRICE_ERROR);
export const fetchLaborTimeIdsByGenericPartErrorSaga = createAction(GET_LABOR_TIME_IDS_BY_GENERIC_PARTS_ERROR);
export const fetchReferencesRepairPriceErrorSaga = createAction(GET_REFERENCES_REPAIR_PRICE_ERROR);
export const fetchBasketErrorSaga = createAction(GET_USER_BASKET_ERROR);
export const fetchAddEstimateKnownLaborTimeErrorSaga = createAction(ADD_ESTIMATE_KNOWN_LABOR_TIME_ERROR);
export const fetchUploadFileReferences99ReferencesErrorSaga = createAction(
  UPLOAD_BASKET_REFERENCES_FILE_ERROR_99_REFERENCES,
);
export const fetchEstimateErrorSaga = createAction(GET_LATEST_ESTIMATE_ERROR);
export const fetchEstimateByIdErrorSaga = createAction(GET_ESTIMATE_BY_ID_ERROR);
export const fetchUploadFileReferencesTechnicalErrorSaga = createAction(UPLOAD_BASKET_REFERENCES_FILE_ERROR_TECHNICAL);
export const fetchUploadFileReferencesApiCallErrorSaga = createAction(UPLOAD_BASKET_REFERENCES_FILE_ERROR_API_CALL);
export const fetchDealerOrderListSaga = createAction(GET_ORDER_LIST_ERROR);
export const fetchOrderByIdErrorSaga = createAction(GET_ORDER_BY_ID_ERROR);
export const fetchFulltextSearchErrorSaga = createAction(GET_TEXT_SEARCH_ERROR);
export const fetchOTSInformationErrorSaga = createAction(GET_OTS_INFORMATION_ERROR);
export const fetchIAMFulltextSearchErrorSaga = createAction(GET_IAM_TEXT_SEARCH_ERROR);
export const fetchIAMEquivalentReferencesErrorSaga = createAction(GET_IAM_EQUIVALENT_REFERENCES_ERROR);
export const fetchAutocompleteFulltextSearchErrorSaga = createAction(GET_FULL_TEXT_AUTOCOMPLETE_ERROR);
export const fetchSingleVehicleReferenceErrorSaga = createAction(GET_REFERENCE_DETAILS_ERROR);
export const fetchPlatesForReferenceError = createAction(GET_PLATES_FOR_REFERENCE_ERROR);
export const fetchReferenceTextSearchErrorSaga = createAction(GET_REFERENCE_TEXT_SEARCH_ERROR);
export const fetchVehicleSearchHistoryErrorSaga = createAction(GET_VEHICLE_SEARCH_HISTORY_ERROR);
export const fetchUniversalProductsTreeErrorSaga = createAction(GET_UNIVERSAL_PRODUCTS_TREE_ERROR);
export const fetchUniversalProductsErrorSaga = createAction(GET_UNIVERSAL_PRODUCTS_ERROR);
export const fetchDHLaborTimesErrorSaga = createAction(GET_LABOR_TIMES_ERROR);
export const fetchIAMLaborTimesErrorSaga = createAction(GET_IAM_LABOR_TIMES_ERROR);
export const fetchEstimateHistorySearchErrorSaga = createAction(GET_ESTIMATE_HISTORY_ERROR);
export const fetchGarageInfoErrorSaga = createAction(GET_GARAGE_INFO_ERROR);
export const fetchEstimateSettingsErrorSaga = createAction(GET_ESTIMATE_SETTINGS_ERROR);
export const fetchUploadFileElectronicPartsRepairFileStructureErrorSaga = createAction(
  BACKOFFICE_ELECTRONIC_PARTS_REPAIR_UPLOAD_REFERENCES_FILE_ERROR,
);
export const fetchReplaceFileElectronicPartsRepairErrorSaga = createAction(
  BACKOFFICE_ELECTRONIC_PARTS_REPAIR_REPLACE_REFERENCES_FILE_ERROR,
);

export const fetchFileElectricPartsRepairErrorSaga = createAction(BACKOFFICE_ELECTRONIC_PARTS_REPAIR_GET_FILE_ERROR);
export const fetchIAMReferencesErrorSaga = createAction(GET_IAM_REFERENCES_ERROR);
export const fetchExplodedTreeError = createAction(EXPLODED_TREE_ERROR);
export const fetchIAMExplodedTreeError = createAction(GET_EXPLODED_TREE_IAM_ERROR);
export const fetchIAMServiceProposalsErrorSaga = createAction(GET_IAM_SERVICE_PROPOSAL_ERROR);
export const fetchIAMServiceProposalOperationsErrorSaga = createAction(GET_IAM_SERVICE_PROPOSAL_OPERATIONS_ERROR);
export const fetchIAMRepairMethodsErrorSaga = createAction(GET_IAM_REPAIR_METHODS_ERROR);
export const fetchFileCrossSellingErrorSaga = createAction(BACKOFFICE_CROSS_SELLING_GET_FILE_ERROR);
export const uploadFileCrossSellingErrorSaga = createAction(BACKOFFICE_CROSS_SELLING_UPLOAD_FILE_ERROR);
export const replaceFileCrossSellingErrorSaga = createAction(BACKOFFICE_CROSS_SELLING_REPLACE_FILE_ERROR);
export const deleteFileCrossSellingErrorSaga = createAction(BACKOFFICE_CROSS_SELLING_DELETE_FILE_ERROR);
export const fetchCrossSellingFileReferencesErrorSaga = createAction(
  BACKOFFICE_CROSS_SELLING_GET_REFERENCES_FILE_ERROR,
);
export const fetchCrossSellingReferencesErrorSaga = createAction(BACKOFFICE_CROSS_SELLING_GET_REFERENCES_ERROR);

export const fetchExternalOrdersErrorSaga = createAction(GET_EXTERNAL_ORDER_LIST_ERROR);
export const fetchPromotionErrorSaga = createAction(BACKOFFICE_PROMOTION_ERROR);
export const fetchCurrentPromotionsErrorSaga = createAction(BACKOFFICE_CURRENT_PROMOTIONS_ERROR);
export const fetchFullPromotionErrorSaga = createAction(BACKOFFICE_FULL_PROMOTION_ERROR);

export const fetchPromotionPublishErrorSaga = createAction<{ id: string }>(PROMOTION_SAVE_DISCOUNT_URL);
export const fetchPromotionBannersErrorSaga = createAction(BACKOFFICE_PROMOTION_BANNERS_ERROR);
export const fetchBackofficePromotionUploadReferencesErrorSaga = createAction(
  BACKOFFICE_PROMOTION_UPLOAD_REFERENCES_FILE_ERROR,
);
export const fetchBackofficePromotionUploadTargetedAudienceErrorSaga = createAction(
  BACKOFFICE_PROMOTION_UPLOAD_TARGETED_AUDIENCE_FILE_ERROR,
);
export const fetchPictureErrorSaga = createAction(GET_PICTURE_ERROR);

export const fetchInformationErrorSaga = createAction(BACKOFFICE_INFORMATION_ERROR);
export const fetchSaveInformationErrorSaga = createAction(BACKOFFICE_SAVE_INFORMATION_ERROR);
export const fetchPublishInformationErrorSaga = createAction(BACKOFFICE_PUBLISH_INFORMATION_ERROR);
export const fetchInformationsErrorSaga = createAction(BACKOFFICE_INFORMATIONS_ERROR);
export const handleInformationBannersErrorSaga = createAction(BACKOFFICE_INFORMATION_BANNERS_ERROR);
export const fetchSaveInformationTemplateErrorSaga = createAction(BACKOFFICE_SAVE_INFORMATION_TEMPLATE_ERROR);
export const fetchTechnicalCriteriaErrorSaga = createAction(GET_VEHICLE_TECHNICAL_CRITERIA_ERROR);
export const fetchLevel3ErrorSaga = createAction(GET_LEVEL3_ERROR);
export const fetchTechnicalDataTreeErrorSaga = createAction(GET_IAM_TECHNICAL_DATA_TREE_ERROR);
export const fetchTechnicalDataDetailsErrorSaga = createAction<{
  vehicleKey: string;
  vehicleCode: string;
  categoryIds: string[];
}>(GET_IAM_TECHNICAL_DATA_DETAILS_ERROR);
export const fetchMaintenancePlanErrorSaga = createAction(GET_MAINTENANCE_PLAN_ERROR);
export const fetchMaintenanceValuesErrorSaga = createAction(GET_MAINTENANCE_VALUES_ERROR);
export const fetchIAMServiceOperationsErrorSaga = createAction(GET_IAM_OPERATIONS_TREE_ERROR);
export const sendEmailFromGarageErrorSaga = createAction(SEND_EMAIL_FROM_GARAGE_ERROR);
export const sendEmailReturnRequestErrorSaga = createAction(SEND_RETURN_REQUEST_EMAIL_ERROR);
export const sendEmailToAssistanceErrorSaga = createAction(SEND_EMAIL_TO_ASSISTANCE_ERROR);
export const tireProductsSearchErrorSaga = createAction(TIRES_SEARCH_PRODUCTS_ERROR);
export const vehicleTiresErrorSaga = createAction(VEHICLE_TIRES_ERROR);
export const availableDMSServicesErrorSaga = createAction(GET_AVAILABLE_DMS_SERVICES_ERROR);
export const repairOrderErrorSaga = createAction(GET_REPAIR_ORDER_ERROR);
export const sendEstimateToDMSErrorSaga = createAction(SEND_ESTIMATE_TO_DMS_ERROR);
export const fetchIAMTiresErrorSaga = createAction(GET_IAM_TIRES_ERROR);
export const fetchMprValidationErrorSaga = createAction(GET_MPR_VALIDATION_ERROR);
export const fetchTireBrandCategoriesErrorSaga = createAction(GET_TIRE_BRAND_CATEGORIES_ERROR);
export const fetchGetTireBrandCategoriesErrorSaga = createAction(GET_TIRE_BRAND_CATEGORIES);
export const fetchReuseStocksErrorSaga = createAction(GET_REUSE_STOCK_ERROR);
export const fetchUploadOrderListReferencesTechnicalErrorSaga = createAction(
  UPLOAD_ORDER_LIST_REFERENCES_ERROR_TECHNICAL,
);
export const fetchUploadOrderListReferencesApiCallErrorSaga = createAction(UPLOAD_ORDER_LIST_REFERENCES_ERROR_API_CALL);
export const fetchUploadOrderListReferencesResponseNotReceivedErrorSaga = createAction(
  ADD_ORDER_LIST_REFERENCES_TO_CART_ERROR,
);
export const fetchFileUploadOrderListReferences99ReferencesErrorSaga = createAction(
  UPLOAD_ORDER_LIST_REFERENCES_FILE_ERROR_99_REFERENCES,
);
export const fetchFileUploadOrderListReferencesFileErrorSaga = createAction(UPLOAD_ORDER_LIST_REFERENCES_FILE_ERROR);
export const sendIAMRepairMethodDetailErrorSaga = createAction(GET_IAM_REPAIR_METHOD_DETAIL_ERROR);
export const getBundlesErrorSaga = createAction(GET_MY_STORE_BUNDLES_ERROR);
export const fetchIAMRepairMethodsTreeErrorSaga = createAction(GET_IAM_REPAIR_METHODS_TREE_ERROR);
export const getMyStoreTiresErrorSaga = createAction(GET_MY_STORE_TIRES_ERROR);
export const fetchBundlesAutocompleteFulltextSearchErrorSaga = createAction(AUTOCOMPLETE_BUNDLES_SEARCH_ERROR);
export const sendClaimErrorSaga = createAction(SEND_CLAIM_ERROR);
