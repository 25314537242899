import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { ServerUTCTimeResponse } from '@1po/1po-bff-fe-spec/generated/time/ServerUTCTimeResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { sendGetServerUTCTime } from 'domains/time/Time.api';
import {
  getClientRequestTime,
  setClientRequestTime,
  setIsTimeSynced,
  setServerTime,
  setTimeDifferenceMs,
} from 'domains/time/Time.store';
import { ERROR, FOUND, LOADING, sagaGuard, select } from 'utils';
import * as actions from './Time.store';

const TOLERANCE_MS = 900000; // 900000 = 15 minutes

export function* fetchServerTimeRequest(): SagaIterator {
  yield put(setIsTimeSynced({ searchStatus: LOADING }));
  yield put(setClientRequestTime(Date.now()));
  yield put(sendGetServerUTCTime());
}

export function* fetchServerTimeResponse(action: WsResponse<ServerUTCTimeResponse>): SagaIterator {
  const clientResponseTime = Date.now();
  const clientRequestTime = yield* select(getClientRequestTime);
  const serverTime = action.payload.serverTimeUTC;

  if (!clientRequestTime) {
    yield put(setIsTimeSynced({ searchStatus: ERROR }));
    return;
  }
  const clientAvgTime = (clientRequestTime + clientResponseTime) / 2;
  const timeDifferenceMs = Math.abs(clientAvgTime - serverTime);
  const isTimeSynced = timeDifferenceMs <= TOLERANCE_MS;

  yield put(setTimeDifferenceMs(timeDifferenceMs));
  yield put(setServerTime(serverTime));
  yield put(setIsTimeSynced({ searchStatus: FOUND, data: isTimeSynced }));
}

export function* TimeSagas(): SagaIterator {
  yield takeEvery(actions.fetchServerTimeRequest.type, sagaGuard(fetchServerTimeRequest));
  yield takeEvery(actions.fetchServerTimeResponse.type, sagaGuard(fetchServerTimeResponse));
}
