import React from 'react';
import Banner from 'pages/UniversalProductsPage/Banner';
import Header from 'pages/UniversalProductsPage/Header';
import Menu from 'pages/UniversalProductsPage/Menu';
import { Flex } from 'UI';

const LandingPage = ({ level1 }: { level1?: string }) => {
  return (
    <Flex direction={'column'} gap={30}>
      <Header />
      <Banner />
      <Menu level1={level1} />
    </Flex>
  );
};
export default LandingPage;
