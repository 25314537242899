import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServerTimeRequest, getIsTimeSynced } from 'domains/time/Time.store';
import { getUserProfileSearchStatus } from 'domains/user';
import { FOUND, LOADING } from 'utils';

export const useFetchIsTimeSynced = () => {
  const userProfileSearchStatus = useSelector(getUserProfileSearchStatus);
  const dispatch = useDispatch();
  const isTimeSyncedSearchData = useSelector(getIsTimeSynced);

  useEffect(() => {
    if (
      isTimeSyncedSearchData.searchStatus !== LOADING &&
      isTimeSyncedSearchData.data === undefined &&
      userProfileSearchStatus === FOUND
    ) {
      dispatch(fetchServerTimeRequest());
    }
  }, [dispatch, isTimeSyncedSearchData, userProfileSearchStatus]);

  return isTimeSyncedSearchData;
};
