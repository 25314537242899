import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_UNIVERSAL_PRODUCTS } from 'app/AppRouter';
import { DataContainer } from 'components/DataContainer';
import { useUniversalProductsTree } from 'domains/catalog/Catalog.requests';
import MainCategories from 'pages/UniversalProductsPage/Menu/MainCategories';
import SubCategories from 'pages/UniversalProductsPage/Menu/SubCategories';
import { Flex } from 'UI';
import { getData } from 'utils';

const DEFAULT_ID = 'N-33909';

const Menu = ({ level1 }: { level1?: string }) => {
  const universalProductsTree = useUniversalProductsTree();
  const history = useHistory();
  const openCategoryId = level1 ?? DEFAULT_ID;

  const getLevel2Categories = () => {
    const mainCategory = getData(universalProductsTree)?.find((up) => up.nodeId === openCategoryId);
    return mainCategory?.childCategories ?? [];
  };

  const setOpenCategoryId = (nodeId: string) => {
    history.push(`${ROUTER_UNIVERSAL_PRODUCTS}/${nodeId}`);
  };

  useEffect(() => {
    if (!level1) {
      history.replace(`${ROUTER_UNIVERSAL_PRODUCTS}/${DEFAULT_ID}`);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DataContainer data={universalProductsTree}>
      <Flex gap={30}>
        <MainCategories
          universalProductsTree={getData(universalProductsTree) ?? []}
          openCategoryId={openCategoryId}
          setOpenCategoryId={setOpenCategoryId}
        />
        <SubCategories openCategoryId={openCategoryId} level2Categories={getLevel2Categories()} />
      </Flex>
    </DataContainer>
  );
};

export default Menu;
