import { APP_NAMESPACE } from 'app/App.constants';
import { SearchData } from 'utils';

export const TIME_NAMESPACE = `${APP_NAMESPACE}/TIME`;

export const GET_SERVER_TIME_REQUEST = `${TIME_NAMESPACE}/GET_SERVER_TIME_REQUEST`;

export interface TimeState {
  clientRequestTime: number | null;
  serverTime: number | null;
  timeDifferenceMs: number | null;
  isTimeSynced: SearchData<boolean>;
  showTimeIsNotSyncedPopup: boolean;
}
