import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  FreeBundle,
  LaborTime,
  OtherItem,
  Reference,
  WasteRecycling,
} from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Estimate';
import { Document, Page, View } from '@react-pdf/renderer';
import moment from 'moment';
import { EprelPicture, getBrandLogo } from 'components/Pdf/PdfSvg';
import {
  Card,
  Divider,
  Input,
  LeadText,
  PageNumber,
  Section,
  Spacer,
  Subtitle,
  Table,
  Text,
  TextBold,
  TextDark,
  Title,
  VerticalSpacer,
} from 'components/Pdf/PdfUI';
import { styles } from 'components/Pdf/PdfUI.styles';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import { PdfFilterProps } from 'pages/EstimatePage/EstimateModifyTab';
import { EstimatePdfData, TireReference } from 'pages/EstimatePdf/EstimatePdfPage';
import { getCondArrayItem } from 'utils';
import { textFormatter } from 'utils/format';

const Header = ({ data }: { data: EstimatePdfData }) => {
  const estimateValidityUnit = data.validityUnit === 'WEEK' ? 'week' : 'month';
  const validityDate = textFormatter.formatDate(
    moment(data.date).add(data.estimateValidFor, estimateValidityUnit).toDate(),
  );
  const date = textFormatter.formatDate(data.date);
  return (
    <View>
      <View style={styles.row}>
        <Title>{data.text.title}</Title>
        <VerticalSpacer width={5} />
        <Title>{data.sequenceNumber ?? ''}</Title>
      </View>
      <View style={styles.row}>
        <View style={styles.column}>
          <View style={styles.col}>
            <LeadText>
              <Trans i18nKey={'estimate.pdf.date'} tOptions={{ date }}>
                Date: {{ date }}
              </Trans>
            </LeadText>
          </View>
        </View>
        <View style={[styles.column, { alignItems: 'flex-end' }]}>
          <View style={styles.col}>
            {data.estimateValidFor && (
              <LeadText>
                <Trans i18nKey={'estimate.pdf.commercial_validity'} tOptions={{ validityDate }}>
                  Commercial estimate validity: {{ validityDate }}
                </Trans>
              </LeadText>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

const VehicleCardImpl = ({
  title,
  modelType,
  engine,
  gearbox,
  vin,
  vrn,
  manufacturingDate,
  mileage,
  administrationNumber,
  parkNumber,
}: {
  title: string;
  gearbox: string | undefined;
  modelType: string | undefined;
  engine: string | undefined;
  vin: string | undefined;
  vrn: string | undefined;
  manufacturingDate: string | undefined;
  mileage: string | undefined;
  administrationNumber: string | undefined;
  parkNumber: string | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <Card title={t('common.vehicle', 'Vehicle')} width={265} height={'lg'}>
      <View style={styles.row}>
        <View
          style={[
            styles.column,
            {
              marginLeft: 10,
              marginRight: 7,
              marginTop: 7,
              marginBottom: 7,
              maxWidth: 120,
              justifyContent: 'space-between',
            },
          ]}
        >
          <View style={styles.flex}>
            <View key={'content-title'} style={{ width: 126, height: title.length > 30 ? 22 : 11 }}>
              <Section>{title}</Section>
            </View>
            <View key={'content-model-type'} style={{ width: 126, height: 11 }}>
              <Text>{modelType}</Text>
            </View>
            <View key={'content-engine'} style={{ width: 126, height: 11 }}>
              <Text>{engine}</Text>
            </View>
            <View key={'content-gearbox'} style={{ width: 126, height: 22 }}>
              <Text>{gearbox}</Text>
            </View>
          </View>
          <View>
            <View key={'input-0'}>
              <Input>
                <Text>{t('estimate.pdf.vin', 'VIN : {{ vin }}', { vin })}</Text>
              </Input>
            </View>
            <View style={{ marginTop: 5 }} key={'input-1'}>
              <Input>
                <Text>{t('estimate.pdf.vrn', 'VRN : {{ vrn }}', { vrn })}</Text>
              </Input>
            </View>
          </View>
        </View>
        <View
          style={[
            styles.column,
            {
              marginLeft: 7,
              marginRight: 10,
              marginTop: 7,
              marginBottom: 7,
              minWidth: 100,
              maxWidth: 115,
              justifyContent: 'flex-end',
            },
          ]}
        >
          <View style={styles.flex}>
            <View key={'input-second-0'}>
              <Input>
                <Text>
                  {t('estimate.pdf.manufacturing_date', '1st Circulation date: {{ manufacturingDate }}', {
                    manufacturingDate,
                  })}
                </Text>
              </Input>
            </View>
            <View style={{ marginTop: 5 }} key={'input-second-1'}>
              <Input>
                <Text>
                  {t('estimate.pdf.mileage', 'Mileage: {{ mileage }}', {
                    mileage,
                  })}
                </Text>
              </Input>
            </View>
            <View style={{ marginTop: 5 }} key={'input-second-2'}>
              <Input>
                {t('estimate.pdf.administration_number', 'Administration number: {{ administrationNumber }}', {
                  administrationNumber,
                })}
              </Input>
            </View>
            <View style={{ marginTop: 5 }} key={'input-second-3'}>
              <Input>
                {t('estimate.pdf.park_number', 'Park number: {{ parkNumber }}', {
                  parkNumber,
                })}
              </Input>
            </View>
          </View>
        </View>
      </View>
    </Card>
  );
};

const IAMVehicleCard = ({ data }: { data: EstimatePdfData }) => {
  const { t } = useTranslation();
  if (!data.vehicleDetail) return null;
  const { vehicleBrand, iamVehicle, vin, vrn, model } = data.vehicleDetail;
  if (!iamVehicle) return null;
  const title = (vehicleBrand ?? '') + (model ? ` ${model}` : '');
  return (
    <VehicleCardImpl
      title={title}
      gearbox={undefined}
      engine={`${iamVehicle.engineCodes} ${iamVehicle.energy}`}
      modelType={`${iamVehicle.bodyType} ${
        iamVehicle.doors ? iamVehicle.doors + t('catalog.vehicle.doors', ' Doors') : ''
      }`}
      administrationNumber={undefined}
      manufacturingDate={undefined}
      parkNumber={undefined}
      mileage={undefined}
      vin={vin}
      vrn={vrn}
    />
  );
};

const DataHubVehicleCard = ({ data }: { data: EstimatePdfData }) => {
  if (!data.vehicleDetail) return null;
  const { dataHubVehicle } = data.vehicleDetail;
  if (!dataHubVehicle) return null;
  const { name, engine, modelType, gearbox } = dataHubVehicle;
  const vehicle = data.vehicleDetail;
  const manufacturingDate = vehicle?.manufacturingDate;
  const mileage = vehicle?.mileage;
  const administrationNumber = vehicle?.administrationNumber;
  const parkNumber = vehicle?.parkNumber;
  const vin = vehicle?.vin;
  const vrn = vehicle?.vrn;

  return (
    <VehicleCardImpl
      title={name ? `${name}` : ''}
      engine={engine}
      modelType={modelType}
      gearbox={gearbox}
      vin={vin}
      vrn={vrn}
      manufacturingDate={manufacturingDate}
      mileage={mileage}
      administrationNumber={administrationNumber}
      parkNumber={parkNumber}
    />
  );
};

const VehicleCard = ({ data }: { data: EstimatePdfData }) => {
  if (!data.vehicleDetail) return null;
  return (
    <View>
      {data.vehicleDetail.dataHubVehicle && <DataHubVehicleCard data={data} />}
      {data.vehicleDetail.dataHubVehicle === undefined && data.vehicleDetail.iamVehicle && (
        <IAMVehicleCard data={data} />
      )}
    </View>
  );
};

const ClientContactCard = ({ data }: { data: EstimatePdfData }) => {
  const client = data.client;
  const name = client?.name;
  const phone = client?.phoneNumber;
  const email = client?.email;

  return (
    <Card title={<Trans i18nKey="estimate.pdf.client_contact">Client contact</Trans>} width={145} height={'lg'}>
      <View style={[styles.col, { marginLeft: 7, marginRight: 7, marginTop: 10, marginBottom: 10 }]}>
        <View style={styles.line}>
          <Text>{name ?? ''}</Text>
        </View>
        <View style={styles.line}>
          <Text>{phone ?? ''}</Text>
        </View>
        <View style={styles.line}>
          <Text>{email ?? ''}</Text>
        </View>
      </View>
    </Card>
  );
};

const GarageCard = ({ data }: { data: EstimatePdfData }) => {
  const garage = data.dealer;
  const address = garage ? getFirstValidAddress(garage) : undefined;
  const institutionNumber = garage?.registrationNumber ?? '';
  const intraCommunityVat = data.intraCommunityVat ?? '';
  const phone = address?.phone ?? '';
  const fax = address?.fax ?? '';
  const email = address?.email ?? '';

  return (
    <Card title={garage?.name ?? ''} width={145} height={'lg'}>
      <View style={[styles.col, { marginLeft: 7, marginRight: 7, marginTop: 5, marginBottom: 15 }]}>
        <Text>
          <Trans i18nKey="estimate.pdf.institution_number">Institution number : {{ institutionNumber }}</Trans>
        </Text>
      </View>
      <Divider />
      <View style={[styles.col, { marginLeft: 7, marginRight: 7, marginTop: 5, marginBottom: 5 }]}>
        <Text>
          <Trans i18nKey="estimate.pdf.intra_community_vat">VAT intra: {{ intraCommunityVat }}</Trans>
        </Text>
      </View>
      <Divider />
      <View style={[styles.col, { marginLeft: 7, marginRight: 7, marginTop: 5, marginBottom: 5 }]}>
        <Text>
          <Trans i18nKey="estimate.pdf.tel">Tel: {{ phone }}</Trans>
        </Text>
      </View>
      <Divider />
      <View style={[styles.col, { marginLeft: 7, marginRight: 7, marginTop: 5, marginBottom: 5 }]}>
        <Text>
          <Trans i18nKey="estimate.pdf.fax">Fax: {{ fax }}</Trans>
        </Text>
      </View>
      <Divider />
      <View style={[styles.col, { marginLeft: 7, marginRight: 7, marginTop: 5, marginBottom: 5 }]}>
        <Text>
          <Trans i18nKey="estimate.pdf.email">Email: {{ email }}</Trans>
        </Text>
      </View>
    </Card>
  );
};

const ObservationCard = ({ data }: { data: EstimatePdfData }) => (
  <Card title={<Trans i18nKey="estimate.pdf.observation">Observation</Trans>} height={'lg'} width={203}>
    <View style={[styles.col, { marginLeft: 7, marginRight: 7, marginTop: 10, marginBottom: 10 }]}>
      {data?.observation && <Text>{data?.observation}</Text>}
    </View>
  </Card>
);

const PriceRow = ({
  title,
  price,
  currency,
}: {
  title: string | ReactNode;
  price: { priceVatExcl: string | undefined; vat: string | undefined };
  currency: string | undefined;
}) => {
  const vatExcl = textFormatter.formatCurrency(Number(price.priceVatExcl), currency);
  const vat = textFormatter.formatCurrency(Number(price.vat), currency);
  return (
    <View style={[styles.row, { height: 15, alignItems: 'center' }]}>
      <View style={{ width: 50 }}>
        <View style={styles.col}>
          <TextDark>{title}</TextDark>
        </View>
      </View>
      <View style={[styles.row, { width: 70 }]}>
        <View style={[styles.column, { minWidth: 30 }]}>
          <View style={styles.col}>
            {price.priceVatExcl && <Text>{<Trans i18nKey="estimate.pdf.vat_excl">VAT excl.: </Trans>}</Text>}
          </View>
        </View>
        <View style={[styles.column, { maxWidth: 40, alignItems: 'flex-end' }]}>
          <View style={styles.col}>{price.priceVatExcl && <Text>{vatExcl}</Text>}</View>
        </View>
      </View>
      <View style={[styles.row, { width: 50 }]}>
        <View style={[styles.column, { minWidth: 15, marginLeft: 5 }]}>
          <View style={styles.col}>
            {price.vat && <Text>{<Trans i18nKey="estimate.pdf.vat_value">VAT: </Trans>}</Text>}
          </View>
        </View>
        <View style={[styles.column, { maxWidth: 30, alignItems: 'flex-end' }]}>
          <View style={styles.col}>{price.priceVatExcl && <Text>{vat}</Text>}</View>
        </View>
      </View>
    </View>
  );
};

const OrderDetailsCard = ({ data }: { data: EstimatePdfData }) => {
  const { reference, laborTime, tire, other, wasteRecycling, freeBundles } = data.orderDetails;
  return (
    <Card title={<Trans i18nKey="estimate.pdf.order_details">Order details</Trans>} height={'lg'} width={203}>
      <View style={[styles.col, { marginLeft: 7, marginRight: 7, marginTop: 10, marginBottom: 10 }]}>
        <PriceRow
          title={<Trans i18nKey="estimate.pdf.references">References</Trans>}
          price={reference}
          currency={data.currency}
        />
        <PriceRow
          title={<Trans i18nKey="estimate.pdf.labor_times">Labor times</Trans>}
          price={laborTime}
          currency={data.currency}
        />
        <PriceRow
          title={<Trans i18nKey="estimate.pdf.bundles">Bundles</Trans>}
          price={freeBundles}
          currency={data.currency}
        />
        <PriceRow title={<Trans i18nKey="estimate.pdf.tires">Tires</Trans>} price={tire} currency={data.currency} />
        <PriceRow title={<Trans i18nKey="estimate.pdf.other">Other</Trans>} price={other} currency={data.currency} />
        <PriceRow
          title={<Trans i18nKey="catalog.waste_recycling">Waste recycling</Trans>}
          price={wasteRecycling}
          currency={data.currency}
        />
      </View>
    </Card>
  );
};

const EstimateTotalCard = ({ data, pdfFilter }: { data: EstimatePdfData; pdfFilter: PdfFilterProps }) => {
  const { currency, price, numberOfItems } = data;
  const { hideDiscounts } = pdfFilter;

  return (
    <Card
      title={<Trans i18nKey="estimate.pdf.estimate_total_items">Estimate total: {{ numberOfItems }} items</Trans>}
      height={'md'}
      width={150}
    >
      <View style={[styles.col, { marginLeft: 7, marginRight: 7, marginTop: 10, marginBottom: 10 }]}>
        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.col}>
              <TextDark>{<Trans i18nKey="estimate.pdf.total_vat_excl">Total VAT excl. :</Trans>}</TextDark>
            </View>
          </View>
          <View style={[styles.col]}>
            <TextDark>{textFormatter.formatCurrency(Number(price.priceVatExcluded), currency)}</TextDark>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.col}>
              <TextDark>{<Trans i18nKey="estimate.pdf.vat_value">VAT: </Trans>}</TextDark>
            </View>
          </View>
          <View style={[styles.col]}>
            <TextDark>{textFormatter.formatCurrency(Number(price.vat), currency)}</TextDark>
          </View>
        </View>
        {!hideDiscounts && Number(price.discount) > 0 && (
          <View style={styles.row}>
            <View style={styles.column}>
              <View style={styles.col}>
                <TextDark>{<Trans i18nKey="estimate.pdf.discount">Discount</Trans>}: </TextDark>
              </View>
            </View>
            <View style={[styles.col]}>
              <TextDark>{textFormatter.formatCurrency(Number(price.discount), currency)}</TextDark>
            </View>
          </View>
        )}
        <Spacer />
        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.col}>
              <Section>{<Trans i18nKey="estimate.pdf.total_vat_incl">TOTAL VAT incl. :</Trans>}</Section>
            </View>
          </View>
          <View style={[styles.col]}>
            <Section>{textFormatter.formatCurrency(Number(price.priceVatIncluded), currency)}</Section>
          </View>
        </View>
      </View>
    </Card>
  );
};

export const EstimatePage = ({
  data,
  pdfFilter,
  estimateLogo,
}: {
  data: EstimatePdfData;
  pdfFilter: PdfFilterProps;
  estimateLogo: string | undefined;
}) => {
  const { hideReferenceNumbers, hideDiscounts } = pdfFilter;
  const getReferencesColumns = (isTires: boolean) => {
    const getWidthModifier = () => {
      if (hideReferenceNumbers && hideDiscounts) return 32;
      if (hideReferenceNumbers || hideDiscounts) return 13;
      return 0;
    };
    return [
      ...getCondArrayItem(
        !hideReferenceNumbers &&
          !isTires && {
            title: data.text.reference,
            width: 81 + getWidthModifier(),
            render: (item: Reference) => (
              <Text>{textFormatter.formatTextOverflow({ src: item.referenceNumber, maxWordLength: 23 })}</Text>
            ),
          },
      ),
      ...getCondArrayItem(
        isTires && {
          title: data.text.eprel,
          width: 81 + getWidthModifier(),
          render: () => <></>,
          renderNode: (item: TireReference) => (
            <Text>{item.eprelBase64 && <EprelPicture eprelBase64={item.eprelBase64} />}</Text>
          ),
        },
      ),
      {
        title: data.text.designation,
        width: 79 + getWidthModifier(),
        render: (item: Reference | TireReference) => {
          if ('brand' in item && 'tireDimensions' in item) {
            return (
              <Text>
                {textFormatter.formatTextOverflow({
                  src: `${item.brand ?? ''} ${item.tireDimensions ? `- ${item.tireDimensions}` : ''} ${
                    item.designation ?? ''
                  }`,
                  maxWordLength: 23,
                })}
              </Text>
            );
          }
          return <Text>{textFormatter.formatTextOverflow({ src: item.designation, maxWordLength: 23 })}</Text>;
        },
      },
      {
        title: data.text.unit_price,
        width: 79 + getWidthModifier(),
        render: (item: Reference) => {
          const value = !hideDiscounts ? Number(item.unitPrice) : Number(item.priceVatExcluded) / Number(item.quantity);
          return <Text>{item.unitPrice ? textFormatter.formatCurrency(value, data.currency) : ''}</Text>;
        },
      },
      {
        title: data.text.quantity,
        width: 79 + getWidthModifier(),
        render: (item: Reference) => (
          <Text>{item.quantity ? textFormatter.formatNumber(Number(item.quantity)) : ''}</Text>
        ),
      },
      ...getCondArrayItem(
        !hideDiscounts && {
          title: data.text.discount,
          width: 79 + getWidthModifier(),
          render: (item: Reference) => (
            <Text>
              {item.discount
                ? item.discountType === 'VALUE_TO_EXTRACT'
                  ? textFormatter.formatCurrency(item.discount, data.currency)
                  : textFormatter.formatPercentDecimal(Number(item.discount))
                : ''}
            </Text>
          ),
        },
      ),
      {
        title: data.text.price_vat_excl,
        width: 79 + getWidthModifier(),
        render: (item: Reference) => (
          <TextBold>
            {item.priceVatExcluded ? textFormatter.formatCurrency(item.priceVatExcluded, data.currency) : ''}
          </TextBold>
        ),
      },
      {
        title: data.text.vat,
        width: 79 + getWidthModifier(),
        render: (item: Reference) => (
          <Text>{item.vatPercentage ? textFormatter.formatPercentDecimal(Number(item.vatPercentage)) : ''}</Text>
        ),
      },
    ];
  };

  const getLaborTimeColumns = () => {
    const widthModifier = hideDiscounts ? 10 : 0;
    return [
      {
        title: <Trans i18nKey="estimate.pdf.codes">Codes</Trans>,
        width: 72 + widthModifier,
        render: (item: LaborTime) => (
          <Text>{textFormatter.formatTextOverflow({ src: item.code, maxWordLength: 20 })}</Text>
        ),
      },
      {
        title: <Trans i18nKey="estimate.pdf.designation">Designation</Trans>,
        width: 69 + widthModifier,
        render: (item: LaborTime) => (
          <Text>{textFormatter.formatTextOverflow({ src: item.designation, maxWordLength: 20 })}</Text>
        ),
      },
      {
        title: <Trans i18nKey="estimate.pdf.rate">Rate</Trans>,
        width: 69 + widthModifier,
        render: (item: LaborTime) => {
          const value = !hideDiscounts
            ? item.pricePerHour
            : Number(item.priceVatExcluded) / Number(item.numberOfHours) / Number(item.quantity);
          return <Text>{item.pricePerHour ? textFormatter.formatCurrency(value, data.currency) : ''}</Text>;
        },
      },
      {
        title: <Trans i18nKey="estimate.pdf.time">Time</Trans>,
        width: 69 + widthModifier,
        render: (item: LaborTime) => (
          <Text>
            {item.numberOfHours ? textFormatter.formatAmount(Number(item.numberOfHours), 'hour', 'short') : ''}
          </Text>
        ),
      },
      {
        title: <Trans i18nKey="estimate.pdf.quantity">Quantity</Trans>,
        width: 69 + widthModifier,
        render: (item: LaborTime) => (
          <Text>{item.quantity ? textFormatter.formatNumber(Number(item.quantity)) : ''}</Text>
        ),
      },
      ...getCondArrayItem(
        !hideDiscounts && {
          title: <Trans i18nKey="estimate.pdf.discount">Discount</Trans>,
          width: 69,
          render: (item: LaborTime) => (
            <Text>
              {item.discount
                ? item.discountType === 'VALUE_TO_EXTRACT'
                  ? textFormatter.formatCurrency(item.discount, data.currency)
                  : textFormatter.formatPercentDecimal(Number(item.discount))
                : ''}
            </Text>
          ),
        },
      ),
      {
        title: <Trans i18nKey="estimate.pdf.price_vat_excl">Price VAT Excl.</Trans>,
        width: 69 + widthModifier,
        render: (item: LaborTime) => (
          <TextBold>
            {item.priceVatExcluded ? textFormatter.formatCurrency(item.priceVatExcluded, data.currency) : ''}
          </TextBold>
        ),
      },
      {
        title: <Trans i18nKey="estimate.pdf.vat">VAT</Trans>,
        width: 69 + widthModifier,
        render: (item: LaborTime) => (
          <Text>{item.vatPercentage ? textFormatter.formatPercentDecimal(Number(item.vatPercentage)) : ''}</Text>
        ),
      },
    ];
  };

  const getFreeBundlesColumns = () => {
    const getWidthModifier = () => {
      if (hideDiscounts) return 16;
      return 0;
    };
    return [
      {
        title: data.text.designation,
        width: 160 + getWidthModifier(),
        render: (item: FreeBundle) => (
          <Text>{textFormatter.formatTextOverflow({ src: item.name, maxWordLength: 23 })}</Text>
        ),
      },
      {
        title: data.text.unit_price,
        width: 79 + getWidthModifier(),
        render: (item: FreeBundle) => {
          const value = !hideDiscounts ? Number(item.unitPrice) : Number(item.priceVatExcluded) / Number(item.quantity);
          return <Text>{item.unitPrice ? textFormatter.formatCurrency(value, data.currency) : ''}</Text>;
        },
      },
      {
        title: data.text.quantity,
        width: 79 + getWidthModifier(),
        render: (item: FreeBundle) => (
          <Text>{item.quantity ? textFormatter.formatNumber(Number(item.quantity)) : ''}</Text>
        ),
      },
      ...getCondArrayItem(
        !hideDiscounts && {
          title: data.text.discount,
          width: 79 + getWidthModifier(),
          render: (item: FreeBundle) => (
            <Text>
              {item.discount
                ? item.discountType === 'VALUE_TO_EXTRACT'
                  ? textFormatter.formatCurrency(item.discount, data.currency)
                  : textFormatter.formatPercentDecimal(Number(item.discount))
                : ''}
            </Text>
          ),
        },
      ),
      {
        title: data.text.price_vat_excl,
        width: 79 + getWidthModifier(),
        render: (item: FreeBundle) => (
          <TextBold>
            {item.priceVatExcluded ? textFormatter.formatCurrency(item.priceVatExcluded, data.currency) : ''}
          </TextBold>
        ),
      },
      {
        title: data.text.vat,
        width: 79 + getWidthModifier(),
        render: (item: FreeBundle) => (
          <Text>{item.vatPercentage ? textFormatter.formatPercentDecimal(Number(item.vatPercentage)) : ''}</Text>
        ),
      },
    ];
  };

  const getOtherItemColumns = () => {
    return [
      {
        title: <Trans i18nKey="estimate.pdf.other_items">Other items</Trans>,
        width: 319,
        render: (item: OtherItem) => <Text>{item.designation}</Text>,
      },
      {
        title: <Trans i18nKey="estimate.pdf.quantity">Quantity</Trans>,
        width: 79,
        render: (item: OtherItem) => (
          <Text>{item.quantity ? textFormatter.formatNumber(Number(item.quantity)) : ''}</Text>
        ),
      },
      {
        title: <Trans i18nKey="estimate.pdf.price_vat_excl">Price VAT Excl.</Trans>,
        width: 78,
        render: (item: OtherItem) => (
          <TextBold>
            {item.priceVatExcluded ? textFormatter.formatCurrency(item.priceVatExcluded, data.currency) : ''}
          </TextBold>
        ),
      },
      {
        title: <Trans i18nKey="estimate.pdf.vat">VAT</Trans>,
        width: 79,
        render: (item: OtherItem) => (
          <Text>{item.vatPercentage ? textFormatter.formatPercentDecimal(Number(item.vatPercentage)) : ''}</Text>
        ),
      },
    ];
  };

  const getWasteRecyclingColumns = () => {
    return [
      {
        title: <Trans i18nKey="estimate.pdf.designation">Designation</Trans>,
        width: 397,
        render: (item: WasteRecycling) => <Text>{item.designation}</Text>,
      },
      {
        title: <Trans i18nKey="estimate.pdf.price_vat_excl">Price VAT Excl.</Trans>,
        width: 79,
        render: (item: WasteRecycling) => (
          <TextBold>
            {item.priceVatExcluded ? textFormatter.formatCurrency(item.priceVatExcluded, data.currency) : ''}
          </TextBold>
        ),
      },
      {
        title: <Trans i18nKey="estimate.pdf.vat">VAT</Trans>,
        width: 79,
        render: (item: WasteRecycling) => (
          <Text>{item.vatPercentage ? textFormatter.formatPercentDecimal(Number(item.vatPercentage)) : ''}</Text>
        ),
      },
    ];
  };

  return (
    <Document>
      <Page size={'A4'} style={styles.page}>
        <View style={[styles.row, { position: 'absolute', left: '50%' }]}>{getBrandLogo(estimateLogo)}</View>
        <PageNumber />
        <Header data={data} />
        <Spacer />
        <View style={styles.row}>
          {data.vehicleDetail ? <VehicleCard data={data} /> : null}
          <ClientContactCard data={data} />
          <GarageCard data={data} />
        </View>
        {data.reference.length > 0 && (
          <>
            <Spacer />
            <Spacer />
            <Spacer />
            <Subtitle>
              <Trans i18nKey="estimate.pdf.references">References</Trans>
            </Subtitle>
            <Spacer />
            <Table data={data.reference} columns={getReferencesColumns(false)} />
          </>
        )}
        {data.laborTime.length > 0 && (
          <>
            <Spacer />
            <Spacer />
            <Spacer />
            <Subtitle>
              <Trans i18nKey="estimate.pdf.labor_times">Labor times</Trans>
            </Subtitle>
            <Spacer />
            <Table data={data.laborTime} columns={getLaborTimeColumns()} />
          </>
        )}
        {data.freeBundles.length > 0 && (
          <>
            <Spacer />
            <Spacer />
            <Spacer />
            <Subtitle>
              <Trans i18nKey="estimate.pdf.bundles">Bundles</Trans>
            </Subtitle>
            <Spacer />
            <Table data={data.freeBundles} columns={getFreeBundlesColumns()} />
          </>
        )}
        {data.tire.length > 0 && (
          <>
            <Spacer />
            <Spacer />
            <Spacer />
            <Subtitle>
              <Trans i18nKey="estimate.pdf.tires">Tires</Trans>
            </Subtitle>
            <Spacer />
            <Table data={data.tire} columns={getReferencesColumns(true)} />
          </>
        )}
        {data.other.length > 0 && (
          <>
            <Spacer />
            <Spacer />
            <Spacer />
            <Subtitle>
              <Trans i18nKey="estimate.pdf.other">Other</Trans>
            </Subtitle>
            <Spacer />
            <Table data={data.other} columns={getOtherItemColumns()} />
          </>
        )}
        {data.wasteRecycling.length > 0 && (
          <>
            <Spacer />
            <Spacer />
            <Spacer />
            <Subtitle>
              <Trans i18nKey="estimate.pdf.waste_recycling">Waste recycling</Trans>
            </Subtitle>
            <Spacer />
            <Table data={data.wasteRecycling} columns={getWasteRecyclingColumns()} />
          </>
        )}
        <Spacer />
        <Spacer />
        <Spacer />
        <View style={styles.row}>
          <ObservationCard data={data} />
          <OrderDetailsCard data={data} />
          <EstimateTotalCard data={data} pdfFilter={pdfFilter} />
        </View>
        <Spacer />
        <Spacer />
        <Spacer />
        {data.legalMention && (
          <View>
            <Subtitle>
              <Trans i18nKey="estimate.pdf.legal_mention">Legal mention</Trans>
            </Subtitle>
            <Spacer />
            <Text>{data.legalMention}</Text>
          </View>
        )}
      </Page>
    </Document>
  );
};
